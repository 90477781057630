<script lang="ts">import { ChainId } from 'anchor-link';
import { derived } from 'svelte/store';
import { sessionEquals, login, logout } from '~/auth';
import { chains, chainConfig } from '~/config';
import { activeSession, availableSessions } from '~/store';
import Button from '~/components/elements/button.svelte';
import ButtonLogin from '~/components/elements/button/login.svelte';
import Icon from '~/components/elements/icon.svelte';
import Segment from '~/components/elements/segment.svelte';
import Text from '~/components/elements/text.svelte';
$: isActive = (session) => sessionEquals(session, $activeSession);
export let onSelect;
function handleAdd() {
    login().catch((error) => {
        // TODO: some sort of modal or toast for error messages like these
        console.warn('unable to add account', error);
    });
}
const getGroupings = (chainIds) => chainIds
    .map((chainId) => {
    const config = chainConfig(ChainId.from(chainId));
    return {
        chainId,
        name: config.name,
        sessions: $availableSessions.filter((s) => String(s.chainId) === chainId),
    };
})
    .sort((a, b) => a.name.localeCompare(b.name));
const groupings = derived(availableSessions, ($availableSessions) => {
    if ($availableSessions) {
        const chainIds = [
            ...new Set($availableSessions.map((session) => String(session.chainId))),
        ];
        return getGroupings(chainIds);
    }
    return [];
});
export let collapsed = {};
function toggle(chainId) {
    if (collapsed[chainId] !== true) {
        collapsed[chainId] = true;
    }
    else {
        collapsed[chainId] = false;
    }
}
</script>

<style type="scss">.list {
  margin: 10px;
}
.list .add-account {
  padding: 0 16px;
}
.list .network .header {
  color: var(--dark-grey);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  padding: 6px 10px;
  margin-top: 26px;
  user-select: none;
}
.list .network .accounts {
  list-style-type: none;
  padding: 0 16px;
}
.list .network .accounts.collapsed {
  display: none;
}
.list .network .accounts li {
  cursor: pointer;
  color: var(--main-blue);
  display: flex;
  font-size: 13px;
  font-weight: 500;
  line-height: 33px;
  margin: 10px 0;
  user-select: none;
  text-decoration: none;
}
.list .network .accounts li.active {
  background-color: var(--background-highlight);
  color: var(--main-black);
  border-radius: 8px;
  font-weight: 600;
}
.list .network .accounts li.active > .icon,
.list .network .accounts li.active > .control {
  color: var(--main-blue);
}
.list .network .accounts li.active .control {
  display: flex;
}
.list .network .accounts li > div {
  order: 0;
  flex: 0 1 auto;
}
.list .network .accounts li > div.icon {
  color: var(--main-blue);
  padding: 0 8px;
}
.list .network .accounts li > div.icon :global(.icon) {
  vertical-align: middle;
}
.list .network .accounts li > div.account {
  flex: 1 1 auto;
  padding: 0 2px;
  line-height: 32px;
}
.list .network .accounts li > div.control {
  display: none;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}
.list :global(.button) {
  line-height: 1em;
}

.login {
  margin: 0 26px;
  text-align: center;
}
.login :global(.segment) {
  background: var(--background-highlight);
}
.login .circular-icon {
  background: var(--main-grey);
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  height: 48px;
  width: 48px;
}
.login .circular-icon :global(.icon) {
  position: absolute;
  top: 12px;
  left: 12px;
  right: 12px;
  height: 24px;
  width: 24px;
}
.login h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01px;
  color: var(--main-black);
  margin-top: 15px;
}
.login p {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.01px;
  color: #9898b5;
  margin: 7px 0 11px;
}</style>

{#if $activeSession}
    <div class="list">
        <div class="add-account">
            <Button fluid style="primary" on:action={handleAdd}>
                <Icon name="user-plus" />
                <Text>Add another account</Text>
            </Button>
        </div>
        {#each $groupings as group}
            <div class="network">
                <div class="header" on:click={() => toggle(group.chainId)}>
                    <Text>{group.name} ({group.sessions.length})</Text>
                    <Icon name={collapsed[group.chainId] ? 'chevron-right' : 'chevron-down'} />
                </div>
                <ul class="accounts" class:collapsed={collapsed[group.chainId]}>
                    {#each group.sessions as session}
                        <li class:active={isActive(session)}>
                            <div class="icon" on:click={() => onSelect(session)}>
                                <Icon name={isActive(session) ? 'user-check' : 'user'} />
                            </div>
                            <div class="account" on:click={() => onSelect(session)}>
                                {session.auth.actor}
                            </div>
                            <div class="control" on:click={() => logout(session)}>
                                <Icon name="log-out" size="large" />
                            </div>
                        </li>
                    {/each}
                </ul>
            </div>
        {/each}
    </div>
{:else}
    <div class="login">
        <Segment>
            <div class="circular-icon">
                <Icon size="medium" name="user-check" />
            </div>
            <h3>Supported blockchains</h3>
            <p>
                {chains
                    .filter((chain) => !chain.testnet)
                    .map((chain) => chain.name)
                    .join(', ')}
            </p>
            <ButtonLogin>Login</ButtonLogin>
        </Segment>
    </div>
{/if}
