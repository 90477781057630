<script lang="ts">"use strict";
</script>

<style type="scss">.container {
  display: flex;
  min-height: 60px;
  border-bottom: 1px solid var(--divider-grey);
  align-items: center;
}</style>

<div class="container">
    <slot />
</div>
