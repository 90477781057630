<script lang="ts">import { createEventDispatcher, onMount, setContext } from 'svelte';
import { writable } from 'svelte/store';
const dispatch = createEventDispatcher();
let formFields = {};
let formDisabled = writable(true);
const form = {
    setInput: (name, valid = false) => {
        formFields[name] = valid;
        validate();
    },
    onChange: (response) => {
        formFields[response.name] = response.valid;
        validate();
    },
};
setContext('form', form);
setContext('formDisabled', formDisabled);
onMount(validate);
function validate() {
    $formDisabled = Object.values(formFields).some((v) => v === false);
}
function submit(event) {
    if (!$formDisabled) {
        dispatch('submit', event);
    }
}
</script>

<style type="scss"></style>

<form on:submit|preventDefault={submit}>
    <slot />
</form>
