<script lang="ts">import Input from '~/components/elements/input.svelte';
import ErrorMessage from './errorMessage.svelte';
export let errorMessage = undefined;
export let name = '';
export let fluid = false;
export let focus = false;
export let inputmode = null;
export let isValid = () => true;
export let placeholder = undefined;
export let value = '';
</script>

<style type="scss"></style>

<div>
    <Input
        on:changed
        bind:name
        bind:fluid
        bind:focus
        bind:inputmode
        bind:isValid
        bind:placeholder
        bind:value
    />
    <ErrorMessage {errorMessage} />
</div>
