<script lang="ts">import InputLabelled from '../labelled.svelte';
export let name = '';
export let value = '';
export let errorMessage = undefined;
export let activeSession = undefined;
export let focus = false;
export let fluid = false;
export let loading = false;
export let placeholder = undefined;
const isValid = async (value) => {
    try {
        if (value) {
            loading = true;
            await validateExistence(value);
            loading = false;
        }
        else {
            errorMessage = undefined;
            return false;
        }
    }
    catch (errorObject) {
        errorMessage = errorObject.message;
        return false;
    }
    errorMessage = undefined;
    return true;
};
async function validateExistence(value) {
    if (!activeSession) {
        return;
    }
    return activeSession.client.v1.chain.get_account(value).catch((error) => {
        const isUnkownAccountError = error.toString().includes('exception: unknown key');
        if (loading) {
            loading = false;
        }
        if (isUnkownAccountError) {
            throw {
                valid: false,
                message: 'Account name not found.',
            };
        }
    });
}
</script>

<style type="scss"></style>

<InputLabelled {name} {fluid} {focus} {placeholder} {errorMessage} bind:value {isValid} />
