<script context="module" lang="ts"></script>

<script lang="ts">import { onMount } from 'svelte';
export let name = 'help-circle';
export let spin = false;
export let size = 'regular';
let icons;
onMount(() => {
    import('feather-icons')
        .then((mod) => {
        icons = mod.icons;
    })
        .catch((error) => {
        console.warn('Unable to load feather-icons', error);
    });
});
</script>

<style type="scss">@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.icon {
  --size: 16px;
  display: inline-flex;
  width: var(--size);
  height: var(--size);
}
.icon :global(svg) {
  display: block;
  width: 100%;
  height: 100%;
}
.icon.spin {
  animation: spin 2s linear infinite;
}
.icon.tiny {
  --size: 0.5em;
}
.icon.small {
  --size: 0.75em;
}
.icon.medium {
  --size: 1.25em;
}
.icon.large {
  --size: 1.5em;
}
.icon.huge {
  --size: 2.5em;
}
.icon.massive {
  --size: 4em;
}</style>

<span class={`icon ${size}`} class:spin>
    {#if icons}
        {@html (icons[name] || icons['help-circle']).toSvg()}
    {/if}
</span>
