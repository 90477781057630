<script lang="ts">import InputLabelled from '~/components/elements/input/labelled.svelte';
import { validatePresence } from './validators/presence';
import { validatePublicKey } from './validators/publicKey';
export let name = '';
export let value = '';
export let errorMessage = undefined;
export let valid = false;
export let focus = false;
export let fluid = false;
export let placeholder = undefined;
const validate = async (value) => {
    try {
        validatePresence(value);
        validatePublicKey(value);
    }
    catch (errorObject) {
        errorMessage = errorObject.message;
        valid = false;
        return false;
    }
    errorMessage = undefined;
    valid = true;
    return true;
};
</script>

<style type="scss"></style>

<InputLabelled on:changed {name} {fluid} {focus} {placeholder} bind:value isValid={validate} />
