<script lang="ts">import { activeSession } from '~/store';
import AccountSidebar from '~/components/layout/account/sidebar.svelte';
import AccountSidebarButton from '~/components/layout/account/button.svelte';
import Header from '~/components/layout/header.svelte';
import NavigationSidebar from '~/components/layout/navigation/index.svelte';
import NavigationSidebarButton from '~/components/layout/navigation/button.svelte';
/** Title of the page. */
export let title = '';
export let subtitle = '';
/** Whether or not to show the left navigation */
export let displayNavigation = true;
/** Whether or not to show the divider after the page title */
export let divider = true;
let accountSidebar = false;
let navigationSidebar = false;
</script>

<style type="scss">.dimmer {
  display: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001 !important;
  background-color: rgba(0, 0, 0, 0.5);
}
.dimmer.active {
  display: block;
}

.grid {
  display: grid;
  column-gap: 4em;
  row-gap: calc(4em / 2);
  grid-template-columns: 268px auto 0;
  grid-template-rows: 78px minmax(0, auto);
  grid-template-areas: "leftbar header" "leftbar main";
}
.grid.withoutsidebar {
  grid-template-rows: 78px auto;
  grid-template-columns: 100%;
  grid-template-areas: "header" "main";
}
.grid.withoutsidebar .page-header {
  left: 0;
  right: 0;
}
.grid.navigation {
  max-height: 100vh;
  overflow: hidden;
}
.grid.noRowGap {
  row-gap: 0;
}
.grid :global(.account-button) {
  right: 4em;
}

.page-header {
  display: flex;
  grid-area: header;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: calc(268px + 4em);
  right: 4em;
  height: 78px;
  background: var(--main-white);
}
.page-header.divider {
  border-bottom: 1px solid var(--divider-grey);
}

.page-leftbar {
  min-height: 100vh;
  grid-area: leftbar;
  position: fixed;
  left: 0;
}

.page-main {
  min-height: calc(100vh - 78px - 4em);
  padding-bottom: 4em;
  grid-area: main;
}
.page-main > * {
  margin: 0 auto;
  max-width: 1200px;
}

@media only screen and (max-width: 999px) {
  .grid {
    grid-template-rows: 78px auto;
    grid-template-columns: 100vw;
    grid-template-areas: "header" "main";
  }

  .page-leftbar {
    min-height: auto;
    position: absolute;
  }

  .page-header {
    top: 0;
    left: 0;
    right: 0;
  }

  .page-main {
    min-height: calc(100vh - 78px - 4em);
  }
  .page-main .header {
    padding: 0 25px;
  }
  .page-main .content {
    padding: 0;
  }
}
@media only screen and (max-width: 600px) {
  .page-header {
    left: 0;
    right: 0;
  }
}</style>

<div
    class="grid"
    class:navigation={accountSidebar || navigationSidebar}
    class:withoutsidebar={!displayNavigation || !$activeSession}
    class:noRowGap={!divider}
>
    <div
        class="dimmer"
        class:active={accountSidebar || navigationSidebar}
        on:click={() => {
            accountSidebar = false
            navigationSidebar = false
        }}
    />

    {#if displayNavigation && $activeSession}
        <aside class="page-leftbar">
            <NavigationSidebar bind:open={navigationSidebar} />
        </aside>
    {/if}

    <header class="page-header" class:divider>
        <NavigationSidebarButton bind:open={navigationSidebar} />
        {#if $$slots.submenu}
            <div class="submenu">
                <slot name="submenu" />
            </div>
        {/if}
        <AccountSidebarButton bind:open={accountSidebar} />
    </header>
    <AccountSidebar bind:open={accountSidebar} />

    <div class="page-main">
        <div class="header">
            {#if title}
                <div class="title">
                    <Header {title} {subtitle} />
                </div>
            {/if}
            {#if $$slots.controls}
                <div class="controls">
                    <slot name="controls" />
                </div>
            {/if}
        </div>
        <div class="content">
            <slot />
        </div>
    </div>
</div>
