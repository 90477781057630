<script lang="ts">export let errorMessage;
</script>

<style type="scss">div {
  padding: 10px 5px;
}
div h3 {
  color: var(--error-red);
}</style>

{#if !!errorMessage}
    <div>
        <h3>
            {errorMessage}
        </h3>
    </div>
{/if}
