<script lang="ts">import Completed from '~/pages/send/status/template/completed.svelte';
import { transferData, Step } from '~/pages/send/transfer';
export let toAccount;
export let editable = false;
function changeRecipient() {
    transferData.update((data) => ({
        ...data,
        step: Step.Recipient,
        backStep: data.step,
    }));
}
const changeStep = editable ? changeRecipient : undefined;
</script>

<Completed header="To" {changeStep}>
    <span>{toAccount}</span>
</Completed>
