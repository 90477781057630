<script lang="ts">export let status;
</script>

<style type="scss">pre {
  overflow: auto;
  font-family: monospace;
  padding: 2em;
}</style>

<pre>
    {JSON.stringify(status, null, 4)}
</pre>
