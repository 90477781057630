<script lang="ts">export let width = undefined;
export let variant = undefined;
</script>

<style lang="scss">path {
  fill: var(--dark-grey);
}
.dark path {
  fill: var(--middle-green-eagle);
}
:global(.darkmode) .dark path {
  fill: #ffffff;
}
.white path {
  fill: #ffffff;
}
:global(.darkmode) .white path {
  fill: #c4c4c4;
}</style>

<svg
    viewBox="0 0 850 142"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {width}
    class={variant ? variant : ''}
>
    <path
        d="M146.242 0.500002L165.106 0.5C193.06 0.5 218.566 10.9116 237.904 28.0427V0.500002H274.501V108.507C274.502 108.714 274.503 108.922 274.503 109.13V141.5H237.904V108.621C237.671 74.9946 214.316 46.8227 182.838 38.9998L182.838 141.5H146.242L146.242 0.500002Z"
    />
    <path
        d="M37.571 76.6434V0.500002H0.974365V76.6434C0.974365 112.463 30.2165 141.5 66.2886 141.5C102.361 141.5 131.603 112.463 131.603 76.6434V0.500002H95.0063V76.6434C95.0063 92.3926 82.1489 105.16 66.2886 105.16C50.4283 105.16 37.571 92.3926 37.571 76.6434Z"
    />
    <path
        d="M619.805 141.5H600.945V0.500013L637.542 0.500016V102.976C668.769 95.1291 691.877 67.0404 691.877 33.5925V0.500009L728.473 0.500012V33.5925C728.473 93.1882 679.821 141.5 619.805 141.5Z"
    />
    <path d="M325.738 0.5V141.5H289.141V0.500002L325.738 0.5Z" />
    <path
        d="M849.974 0.500002H743.112V141.5H849.974V105.16H779.709V89.1701H828.243V52.8299H779.709V36.8402H849.974V0.500002Z"
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M444.311 71C444.311 32.0639 476.098 0.500002 515.309 0.500002C554.519 0.500002 586.306 32.0639 586.306 71C586.306 109.936 554.519 141.5 515.309 141.5C476.098 141.5 444.311 109.936 444.311 71ZM515.309 36.8402C496.31 36.8402 480.908 52.1341 480.908 71C480.908 89.8659 496.31 105.16 515.309 105.16C534.308 105.16 549.709 89.8659 549.709 71C549.709 52.1341 534.308 36.8402 515.309 36.8402Z"
    />
    <path
        d="M376.973 71C376.973 52.1341 392.375 36.8402 411.374 36.8402H429.672V0.500002H411.374C372.163 0.500002 340.377 32.0639 340.377 71C340.377 109.936 372.163 141.5 411.374 141.5H429.672V105.16H411.374C392.375 105.16 376.973 89.8659 376.973 71Z"
    />
</svg>
