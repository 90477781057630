<script lang="ts">export let src = '';
export let alt = '';
export let fallbackSrc = '/images/placeholder.png';
export let fallbackAlt = 'placeholder image';
export let width = undefined;
export let height = undefined;
$: srcToUse = src;
$: altToUse = alt;
function useImageFallback() {
    srcToUse = fallbackSrc;
    altToUse = fallbackAlt;
}
</script>

<img src={srcToUse} alt={altToUse} on:error={useImageFallback} {width} {height} />
