<script lang="ts">export let background = '';
export let bordered = false;
</script>

<style type="scss">.segment {
  background: var(--main-grey);
  border-radius: 20px;
  color: var(--main-black);
  padding: 22px 26px;
}
.segment.white {
  background: var(--main-white);
}
.segment.transparent {
  background: transparent;
}
.segment.image {
  background-size: cover;
  background-image: url("/images/unicove-bright-mode-card-1.jpeg");
}
:global(.darkmode) .segment.image {
  background-image: url("/images/unicove-dark-mode-card-1.jpeg");
}
.segment.image-alt {
  background-size: cover;
  background-image: url("/images/unicove-bright-mode-card-2.jpeg");
}
:global(.darkmode) .segment.image-alt {
  background-image: url("/images/unicove-dark-mode-card-2.jpeg");
}
.segment.bordered {
  border: 1px solid var(--divider-grey);
}</style>

<div class="segment {background}" class:bordered>
    <slot />
</div>
