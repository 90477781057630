<script lang="ts">import { activeBlockchain } from '~/store';
import Completed from '~/pages/send/status/template/completed.svelte';
import { Step, transferData } from '../transfer';
export let toAddress;
export let editable = false;
function changeRecipient() {
    transferData.update((data) => ({
        ...data,
        step: Step.Recipient,
        backStep: data.step,
    }));
}
const changeStep = editable ? changeRecipient : undefined;
</script>

<style type="scss">span {
  line-height: 32px;
}</style>

<Completed header="Receiving Address" {changeStep}>
    <span>{toAddress.toLegacyString($activeBlockchain?.coreTokenSymbol.name)}</span>
</Completed>
