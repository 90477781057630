<script lang="ts">import { activeBlockchain } from '~/store';
import InputLabelled from '~/components/elements/input/labelled.svelte';
import { validatePresence } from './validators/presence';
import { validateBalance, validateIsNumber, validateNonZero } from './validators/asset';
export let symbol = $activeBlockchain.coreTokenSymbol;
export let name = '';
export let value = '';
export let allowZero = false;
export let balance = undefined;
export let valid = false;
export let focus = false;
export let fluid = false;
export let placeholder = undefined;
let errorMessage;
$: {
    if (value) {
        errorMessage = undefined;
    }
}
const isValid = (value) => {
    try {
        validatePresence(value);
        validateIsNumber(value, symbol);
        if (!allowZero) {
            validateNonZero(value, symbol);
        }
        if (balance) {
            validateBalance(value, balance);
        }
    }
    catch (errorObject) {
        errorMessage = errorObject.message;
        valid = false;
        return false;
    }
    errorMessage = undefined;
    valid = true;
    return true;
};
</script>

<style type="scss"></style>

<InputLabelled
    bind:value
    on:changed
    {errorMessage}
    {fluid}
    {focus}
    {name}
    {placeholder}
    {isValid}
    inputmode="decimal"
/>
