<script context="module" lang="ts">const tokenIcons = {
    'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906-eosio.token-eos': {
        name: 'EOS',
        src: '/images/token-eos.svg',
        darkSrc: '/images/token-eos-dark.svg',
    },
    '4667b205c6838ef70ff7988f6e8257e8be0e1284a2f59699054a018f743b1d11-eosio.token-tlos': {
        name: 'TELOS',
        src: '/images/chains/telos-light.svg',
        darkSrc: '/images/chains/telos-dark.svg',
    },
};
</script>

<script lang="ts">import { tokens } from '~/stores/tokens';
import Image from '~/components/elements/image.svelte';
import { darkMode } from '~/store';
export let tokenKey;
export let width = '32';
export let height = '32';
let src;
let name;
$: {
    if (tokenIcons[tokenKey]) {
        const icon = tokenIcons[tokenKey];
        src = $darkMode ? icon.darkSrc || icon.src : icon.src;
        name = icon.name;
    }
    else {
        const token = ($tokens || []).find((t) => t.key === tokenKey);
        if (token) {
            src = token.logo;
            name = String(token.name);
        }
    }
}
</script>

{#if src}
    <Image {height} {width} alt={name} {src} />
{/if}
