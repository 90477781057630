<script lang="ts">import { getContext, onMount } from 'svelte';
import { createEventDispatcher } from 'svelte';
export let disabled = false;
export let focus = false;
export let inputmode = undefined;
export let name = '';
export let placeholder = '';
export let value = '';
const inputmodeParam = inputmode;
/** Whether or not the button should go full width */
export let fluid = false;
let ref;
export let isValid = () => true;
export let assumeValid = false;
let timer;
let delay = 300;
// Get parent form context (if exists)
const form = getContext('form');
const setInitialFormValidation = async () => {
    form.setInput(name, isValid ? await isValid(value) : true);
};
if (form) {
    setInitialFormValidation();
}
onMount(() => {
    if (focus) {
        ref.focus();
    }
});
// Dispatched when button is activated via keyboard or click
const dispatch = createEventDispatcher();
function invalidate(name, value) {
    if (form) {
        form.onChange({
            name,
            valid: assumeValid,
            value,
        });
    }
    dispatch('changed', {
        name,
        valid: assumeValid,
        value,
    });
}
const debounce = (e) => {
    timer && clearTimeout(timer);
    value = e.currentTarget.value;
    // Immediately invalidate
    invalidate(name, value);
    // Debounce actual validation
    timer = setTimeout(async () => {
        const response = {
            name,
            valid: isValid ? await isValid(value) : true,
            value,
        };
        // If a form context exists, signal change events
        if (form) {
            form.onChange(response);
        }
        dispatch('changed', response);
    }, delay);
};
const handleInput = (e) => debounce(e);
$: {
    isValid(value);
}
</script>

<style type="scss">input {
  background: var(--main-grey);
  border: 1px solid var(--dark-grey);
  border-radius: 12px;
  color: var(--main-black);
  font-size: 14px;
  padding: 10px 12px;
}
input:focus {
  border: 1px solid var(--lapis-lazuli);
  color: var(--main-black);
  outline: none;
}
input.fullWidth {
  width: 100%;
}
:global(body.darkmode) input {
  background-color: #252525;
  border-color: var(--middle-green-eagle);
}</style>

<input
    on:input={handleInput}
    class={fluid ? 'fullWidth' : ''}
    type="text"
    {name}
    {disabled}
    inputmode={inputmodeParam}
    {placeholder}
    bind:this={ref}
    bind:value
    autocapitalize="none"
    autocorrect="off"
    autocomplete="off"
/>
